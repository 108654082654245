import React, { FC, useCallback, useState } from "react";

import { useDispatch } from "react-redux";

import { Input, Tooltip } from "antd";
import { MenuItem, Dropdown, Menu } from "pyxis-ui-kit";

import {
  deleteAgencyFolder,
  downloadAgencyFolderAction,
  renameAgencyFolder,
} from "../../../../store/actions/agencyFile";
import { AgencyFolder } from "../../../../store/types";

import DeleteResourceModal from "../DeleteResourceModal";

import { downloadFolderContents, getDataFromParams } from "../../utils";
import { getUrlProperties } from "../../../../utilities/common-function";

import CopyResourceModal from "../CopyResourceModal";

import renameIcon from "../../../../assets/svg/renameIcon.svg";
import deleteIcon from "../../../../assets/svg/delete_agency.svg";
import downloadIcon from "../../../../assets/svg/downloadIcon.svg";
import dropdownIcon from "../../../../assets/svg/dropdown-icon.svg";
import folderIcon from "../../../../assets/svg/agency-folder-icon.svg";
import copyFolderIcon from "../../../../assets/svg/CopyFolderIcon.svg";
import { store } from "../../../../store/store";
import { openToast } from "../../../../Toasts";

interface AgencyFolderItemProps {
  folder: AgencyFolder;
  index: number;
  handleFolderClick: Function;
  isForPublic?: boolean;
}

const maxTextLength = 50;
const AgencyFolderItem: FC<AgencyFolderItemProps> = ({
  folder,
  index,
  handleFolderClick,
  isForPublic = false,
}) => {
  const dispatch = useDispatch();
  const { search } = getUrlProperties();
  const folders = getDataFromParams(search, "folder");

  const [isDeleteAgencyModalOpen, setIsDeleteAgencyModalOpen] = useState<
    boolean
  >(false);
  const [isCopyFolderModalOpen, setIsCopyFolderModalOpen] = useState<boolean>(
    false
  );
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false); // State to track edit mode
  const [folderName, setFolderName] = useState<string>(folder.name); // Editable folder name
  const [selectedFolder, setSelectedFolders] = useState<AgencyFolder | null>(
    null
  );
  const [isInputTextLengthExceeds, setIsInputTextLengthExceeds] = useState<
    boolean
  >(false);
  const handleAgencyFolderDelete = useCallback(() => {
    dispatch(
      deleteAgencyFolder({
        id: String(selectedFolder.id),
        agencyId: selectedFolder.agency_id,
      })
    );
    setIsDeleteAgencyModalOpen(false);
  }, [dispatch, selectedFolder]);

  const downloadFile = (url) => {
    const a = document.createElement("a");
    a.href = url;

    a.setAttribute("download", "");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleInputChange = useCallback(
    (e) => {
      if (e.target.value.length >= maxTextLength) {
        setIsInputTextLengthExceeds(true);
        return;
      } else {
        setFolderName(e.target.value);
        setIsInputTextLengthExceeds(false);
      }
    },
    [setFolderName]
  );

  const handleMenuClick = useCallback((key: string, folder: AgencyFolder) => {
    setSelectedFolders(folder);
    switch (key) {
      case "copyFolder":
        if (folder?.file_count === 0 && folder.content_count === 0) {
          openToast("info", "Empty folder can't be copied.");
          return;
        }
        setIsCopyFolderModalOpen(true);
        break;

      case "downloadFolder":
        if (folder?.file_count === 0 && folder?.content_count === 0) {
          openToast("info", "Empty folders can't be downloaded.");
          return;
        }
        dispatch(downloadAgencyFolderAction({folderId: String(folder?.id) , agencyId: folder.agency_id, folderName: folder?.name}))
        break;

      case "renameFolder":
        setFolderName(folder.name);
        setIsEditing(true);
        break;

      case "deleteFolder":
        setIsDeleteAgencyModalOpen(true);
        break;

      default:
        return;
    }

    setActiveDropdown(null); // Close dropdown after selection
  }, [dispatch]);

  const handleRenameSubmit = useCallback(() => {
    if (folderName !== folder.name && !!folderName.length) {
      dispatch(renameAgencyFolder({ id: String(folder.id), name: folderName }));
    }
    setIsEditing(false);
  }, [dispatch, folder, folderName]);

  const menu = (folder: AgencyFolder) => (
    <Menu
      style={{ width: "22rem", borderRadius: "1rem" }}
      onClick={(e) => handleMenuClick(String(e.key), folder)} // Cast key to string
    >
      <MenuItem key="copyFolder" className="dropdown__item">
        <img src={copyFolderIcon} alt="Copy folder icon" />
        Copy to my files
      </MenuItem>
      <MenuItem key="downloadFolder" className="dropdown__item">
        <img src={downloadIcon} alt="Download folder icon" />
        Download folder
      </MenuItem>
      {!!folders ? (
        <></>
      ) : (
        <MenuItem key="renameFolder" className="dropdown__item">
          <img src={renameIcon} alt="Rename folder icon" />
          Rename
        </MenuItem>
      )}
      <MenuItem
        key="deleteFolder"
        className="dropdown__item dropdown__item--delete"
      >
        <img src={deleteIcon} alt="Delete folder icon" />
        Delete
      </MenuItem>
    </Menu>
  );
  return (
    <>
      <div
        className="folder-data"
        key={`folder.id`}
        onDoubleClick={() => handleFolderClick(folder)}
      >
        <div className="folder_info_wrapper">
          <img src={folderIcon} alt="Folder Icon" />
          <div className="folder_info">
            {isEditing ? (
              <>
                <Input
                  className="input-field"
                  value={folderName}
                  onChange={handleInputChange}
                  onBlur={handleRenameSubmit}
                  onPressEnter={handleRenameSubmit} // Save the name on pressing Enter
                  autoFocus
                  style={{ fontSize: "1.2rem !important" }}
                  maxLength={maxTextLength}
                />

                {!!isInputTextLengthExceeds ? (
                  <span
                    style={{
                      color: "red",
                      fontSize: "1rem",
                      paddingTop: "0.4rem",
                    }}
                  >
                    Maximum length for the title is {maxTextLength} characters.
                  </span>
                ) : (
                  <div />
                )}
              </>
            ) : (
              <Tooltip
                placement="bottomRight"
                title={folder.name}
                key={folder.id + folder.name}
              >
                <div className="folder_name">{folder?.name}</div>
              </Tooltip>
            )}

            <div className="total_folders">
              {folder.file_count || 0} Files, {folder.content_count || 0}{" "}
              Folders
            </div>
          </div>
        </div>
        {!isForPublic && (
          <Dropdown
            overlay={menu(folder)}
            visible={activeDropdown === index} // Check if this dropdown should be visible
            onVisibleChange={(visible) => {
              setActiveDropdown(visible ? index : null); // Show dropdown only for the active folder
            }}
            trigger={["click"]} // Opens dropdown on click
            placement="bottomRight"
          >
            <div style={{ cursor: "pointer" }}>
              <img src={dropdownIcon} alt="DropDown Icon" />
            </div>
          </Dropdown>
        )}
      </div>

      <DeleteResourceModal
        isModalOpen={isDeleteAgencyModalOpen}
        setModalOpen={setIsDeleteAgencyModalOpen}
        modalTitle={"Delete Folder?"}
        modalDesc={"Are you sure you want to delete this folder."}
        handleDeleteClick={handleAgencyFolderDelete}
      />

      <CopyResourceModal
        isModalOpen={isCopyFolderModalOpen}
        setModalOpen={setIsCopyFolderModalOpen}
        itemToBeCopied={selectedFolder}
        isFolder={true}
      />
    </>
  );
};

export default AgencyFolderItem;
