import React, { useCallback, useState } from "react";
import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AgencyFileDropState } from "../../store/types";
import { downloadFolderStatusDataAction } from "../../store/actions/agencyFile";
import Modal from "../shared/Modal";
import CloseIcon from "../../assets/svg/CloseIcon.svg";
import MinimizeIcon from "../../assets/svg/MinimizeIcon.svg";
import MaximizeIcon from "../../assets/svg/MaximizeIcon.svg";
import CompleteUploadIcon from "../../assets/svg/CompleteUploadIcon.svg";
import FolderIcon from "../../assets/svg/agency-folder-icon.svg";

const DownloadAgencyFolderProgressiveModal: React.FC = () => {
  const dispatch = useDispatch();
  const { downloadFolderStatusData } = useSelector(
    (state: { agencyFileDrop: AgencyFileDropState }) => ({
      ...state.agencyFileDrop,
    })
  );
  const [isMinized, setIsMinimized] = useState(false);
  const handleMinimizeModal = useCallback(() => {
    setIsMinimized(!isMinized);
  }, [isMinized]);

  const handleCloseModal = useCallback(() => {
    dispatch(
      downloadFolderStatusDataAction({
        folderName: "",
        status: "",
        progress: 0,
      })
    );
  }, [dispatch]);

  return !!downloadFolderStatusData.folderName ? (
    <Modal>
      <div className="action-popups-container right-side agency_files_upload">
        {/* we are using three different component because we need three different popup */}
        <div className="download_files_box">
          <div className="content_wrapper">
            <div className="content_title">Downloads</div>
            <div className="modal_action_buttons">
              {!!isMinized ? (
                <div className="action_icon" onClick={handleMinimizeModal}>
                  <img src={MaximizeIcon} alt="Maximize Icon" />
                </div>
              ) : (
                <div className="action_icon" onClick={handleMinimizeModal}>
                  <img src={MinimizeIcon} alt="Minimize Icon" />
                </div>
              )}
              <div className="action_icon" onClick={handleCloseModal}>
                <img src={CloseIcon} alt="Close Icon" />
              </div>
            </div>
          </div>
          {!isMinized ? (
            <div style={{ paddingTop: "1rem", background: "#fff" }}>
              <div className="files_content_wrapper">
                <div>
                  <div className="uploaded-file">
                    <div className="uploaded_file_content_wrapper">
                      <div className="fileTypeIconWrapper uplaoded_file_content">
                        <div>
                          <img src={FolderIcon} alt="File Icon" />
                        </div>
                        <div className="file_title flex flex-col">
                          <Tooltip
                            placement="bottomRight"
                            title={downloadFolderStatusData?.folderName}
                          >
                            <div
                              className="flex"
                              style={{
                                fontWeight: 600,
                                maxWidth: "28rem",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {downloadFolderStatusData?.folderName}
                            </div>
                          </Tooltip>
                          <div style={{ marginTop: "1rem" }}>
                            {downloadFolderStatusData.progress}%
                          </div>
                        </div>
                      </div>
                      <div>
                        {downloadFolderStatusData.status !== "COMPLETED" ? (
                          <div id="loader" />
                        ) : (
                          <div>
                            <img
                              src={CompleteUploadIcon}
                              alt="Upload Completed"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default DownloadAgencyFolderProgressiveModal;
